import { redirect } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// GQL
import { client } from '../../../../../../gql/client';
import {
  UPDATE_ORDER,
  UPDATE_ORDER_RELATIONSHIPS,
} from '../../../../../../gql/mutations/orders';
import {
  CREATE_NEW_IMAGE,
  DELETE_MANY_IMAGES,
} from '../../../../../../gql/mutations/accidents';
import { NEW_IMAGE } from '../../../../../../gql/fragments/image';
import {
  DELETE_MANY_VEHICLE_OCCUPANTS_ACTION,
  UPDATE_ACTION,
  UPDATE_ACTION_RELATIONSHIPS,
} from '../../../../../../gql/mutations/actions';
import { CREATE_POSITION_ACTION } from '../../../../../../gql/mutations/geoPositionActions';
import { NEW_VEHICLE_OCCUPANT_ACTION } from '../../../../../../gql/mutations/vehicles';
import {
  CREATE_NEW_MATERIAL_UNIT,
  DELETE_MANY_MATERIALS,
  UPDATE_MANY_MATERIALS_UNITS,
} from '../../../../../../gql/mutations/materialUnits';
import {
  CREATE_NEW_ANIMAL_UNIT,
  DELETE_MANY_ANIMAL_UNITS,
  UPDATE_MANY_ANIMAL_UNITS,
} from '../../../../../../gql/mutations/animalUnits';
import {
  CREATE_NEW_AUX_MACHINE_UNIT,
  DELETE_MANY_AUX_MACHINE_UNITS,
  UPDATE_MANY_AUX_MACHINE_UNITS,
} from '../../../../../../gql/mutations/auxMachineUnits';
import { NEW_ANIMAL_UNIT } from '../../../../../../gql/fragments/animalUnit';
import { NEW_AUX_MACHINE_UNIT } from '../../../../../../gql/fragments/auxMachineUnit';
import { NEW_MATERIAL_UNIT } from '../../../../../../gql/fragments/materialUnit';
import { GET_IMAGE_BY_ID } from '../../../../../../gql/queries/accidents/accidents';
import { CREATE_EXTERNAL_COMPANY } from '../../../../../../gql/mutations/externalCompanies';
import {
  CREATE_EXTERNAL_WORK,
  DELETE_EXTERNAL_WORKS_BY_IDS,
  UPDATE_EXTERNAL_WORKS,
} from '../../../../../../gql/mutations/externalWork';

// Constants
import { formActionNaming } from '../../../../../../components/forms/ActionForms/constants';
import { UNSPECIFIC } from '../../../../../../types/utils';

// Utils
import { decompressSessionStorage } from '../../../../../../utils/sessionStorage';
import { UploadImage } from '../../../../../../utils/image';

// Types
import { OperatorsSelectionType } from '../../../../../../components/display/tables/formTables/OperatorsSelectionTable/types';
import { NEW_POSITION_ACTION } from '../../../../../../gql/fragments/geoPositionAction';
import { GET_VEHICLE_BY_ID } from '../../../../../../gql/queries/vehicles';
import { GET_ORDER_BY_ID } from '../../../../../../gql/queries/orders/orders';
import { GET_ACTION_BY_ID } from '../../../../../../gql/queries/actions/actions';
import { GET_USER_BY_ID } from '../../../../../../gql/queries/users';
import { GET_ASSET_BY_ID } from '../../../../../../gql/queries/actions/assets';
import { GET_ACTIVITY_BY_ID } from '../../../../../../gql/queries/actions/activitys';
import { OrderState, OrderType } from '../../../../../../types/orders';
import {
  tableIds,
  takeBase,
} from '../../../../../../components/display/tables/types';

// Redux
import { GET_GENERIC_ORDER_BY_ID } from '../../../../../../gql/queries/genericOrders';
import { GET_CAMPAIGN_BY_ID } from '../../../../../../gql/queries/campaign';
import { truncateArrayAfterNull } from '../../../../../../utils/mergeArrays';
import { setCursor } from '../../../../../../state/actions/ui/cursorTables';
import { store } from '../../../../../../state/configureStore';
import { getCursor } from '../../../../../../state/selectors/ui/tables';
import {
  FETCH_EXTERNAL_COMPANIES,
  GET_EXTERNAL_COMPANY_BY_ID,
} from '../../../../../../gql/queries/actions/externalCompanies';
import { GET_MATERIAL_UNIT_BY_ID } from '../../../../../../gql/queries/actions/materials';
import { GET_ANIMAL_UNIT_BY_ID } from '../../../../../../gql/queries/actions/animals';
import { GET_AUX_MACHINE_UNIT_BY_ID } from '../../../../../../gql/queries/actions/auxMachines';
import { AnimalIdTypes, AuxMachineTypes, MaterialIdTypes } from '../../types';
import { GET_EXTERNAL_WORK_BY_ID } from '../../../../../../gql/queries/actions/externalWorks';

export const saveData = async () => {
  const dispatch = store.dispatch;

  const cursorMultimedia = getCursor(tableIds.MULTIMEDIA)(store.getState());

  const orderActionData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_ACTION_DATA) as string
    )
  );

  const { data } = await client.query({
    query: GET_ORDER_BY_ID,
    variables: {
      id: orderActionData.orderId,
    },
  });

  const {
    data: { action },
  } = await client.query({
    query: GET_ACTION_BY_ID,
    variables: {
      id: data.order.actionIDs[0],
    },
  });

  const operatorsAndVehiclesData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(
        formActionNaming.NEW_OPERATORS_AND_VEHICLE_FORM
      ) as string
    )
  );

  const locationData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_LOCATION_FORM) as string
    )
  );

  const editionData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_EDITION_FORM) as string
    )
  );
  const materialsData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_MATERIALS_FORM) as string
    )
  );
  const animalsData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_ANIMALS_FORM) as string
    )
  );
  const machineryData = JSON.parse(
    decompressSessionStorage(
      sessionStorage.getItem(formActionNaming.NEW_MACHINERY_FORM) as string
    )
  );

  try {
    if (orderActionData.orderId && orderActionData.actionId) {
      const driverAndVehicle: any = Object.values(
        operatorsAndVehiclesData?.vehicleTable
      ).reduce((accumulator: any, currentDriverAndVehicle: any) => {
        if (
          currentDriverAndVehicle &&
          Object.keys(currentDriverAndVehicle).length > 0 &&
          currentDriverAndVehicle.driverID
        ) {
          accumulator.push({
            vehicleID: currentDriverAndVehicle.vehicleID,
            driverID: currentDriverAndVehicle.driverID,
          });
        }
        return accumulator;
      }, []);
      const internalOperatorsIDs =
        operatorsAndVehiclesData?.internalOperatorsTable?.reduce(
          (accumulator: string[], currentOperator: OperatorsSelectionType) => {
            if (currentOperator) {
              accumulator.push(currentOperator.id);
            }
            return accumulator;
          },
          []
        );
      const externalOperatorsIDs =
        operatorsAndVehiclesData?.externalOperatorsTable?.reduce(
          (accumulator: string[], currentOperator: OperatorsSelectionType) => {
            if (currentOperator) {
              accumulator.push(currentOperator.id);
            }
            return accumulator;
          },
          []
        ) ?? [];
      const operatorIDs = [];
      if (internalOperatorsIDs && internalOperatorsIDs.length > 0)
        operatorIDs.push(...internalOperatorsIDs);
      if (externalOperatorsIDs && externalOperatorsIDs.length > 0)
        operatorIDs.push(...externalOperatorsIDs);

      const splitHourAndMinutes =
        operatorsAndVehiclesData.totalHours.split(':');
      const minutesTotals =
        parseInt(splitHourAndMinutes[0]) * 60 +
        parseInt(splitHourAndMinutes[1]);
      const pkInitRaw = locationData.actionPkInit.replace(/ /g, '').split('+');
      const pkEndRaw = locationData.actionPkEnd.replace(/ /g, '').split('+');
      const driverAndVehicleResolved: string[] = [];
      const isExternal = operatorsAndVehiclesData.isExternal;
      const externalCompanies = operatorsAndVehiclesData?.externals;

      const externalWorksFetch = await Promise.all(
        action.externalWorksIDs.map(async (externalWorkID: string) => {
          const {
            data: { externalWork },
          } = await client.query({
            query: GET_EXTERNAL_WORK_BY_ID,
            variables: {
              id: externalWorkID,
            },
          });
          return externalWork;
        })
      );

      const externalCompaniesFetch = await Promise.all(
        externalWorksFetch.map(
          async (externalCompanyItem: { externalCompanyIDs: string[] }) => {
            const {
              data: { externalCompany },
            } = await client.query({
              query: GET_EXTERNAL_COMPANY_BY_ID,
              variables: {
                id: externalCompanyItem.externalCompanyIDs[0],
              },
            });
            return externalCompany;
          }
        )
      );
      const externalWorksFetchIDs = externalWorksFetch.map(
        (external) => external.id
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      if (!isExternal && externalWorksFetchIDs.length > 0) {
        await client.mutate({
          mutation: DELETE_EXTERNAL_WORKS_BY_IDS,
          variables: {
            ids: externalWorksFetchIDs,
          },
        });
      }
      if (isExternal && action.vehicleOccupantIDs.length > 0) {
        await client.mutate({
          mutation: UPDATE_ACTION_RELATIONSHIPS,
          variables: {
            input: {
              id: orderActionData.actionId,
              userRelationships: [],
            },
          },
        });
        await client.mutate({
          mutation: DELETE_MANY_VEHICLE_OCCUPANTS_ACTION,
          variables: {
            ids: action.vehicleOccupantIDs,
          },
        });
      }
      if (externalCompanies && externalCompanies.length > 0) {
        if (isExternal) {
          // For externals
          const arrayUpdatedExternalWorks: {
            externalWorkId: string;
            operatorsNumber: number;
          }[] = [];

          externalWorksFetch.forEach((externalWork) => {
            externalCompanies.filter(
              (externalCompany: { id: string; operatorsNumber: string }) => {
                if (
                  externalCompany.id === externalWork.externalCompanyIDs[0] &&
                  Number(externalCompany.operatorsNumber) !==
                    externalWork.operatorsNumber
                ) {
                  arrayUpdatedExternalWorks.push({
                    externalWorkId: externalWork.id,
                    operatorsNumber: Number(externalCompany.operatorsNumber),
                  });
                }
              }
            );
          });

          if (arrayUpdatedExternalWorks.length > 0) {
            arrayUpdatedExternalWorks.forEach(async (externalWorkToUpdate) => {
              await client.mutate({
                mutation: UPDATE_EXTERNAL_WORKS,
                variables: {
                  input: externalWorkToUpdate,
                },
              });
            });
          }

          const newExternalWorks = externalCompanies.filter(
            (externalCompany: { company: string }) => {
              return !externalCompaniesFetch.some(
                (externalCompanyDataFetch) => {
                  return (
                    externalCompany.company ===
                    externalCompanyDataFetch.companyName
                  );
                }
              );
            }
          );

          const removeExternalCompanies = externalCompaniesFetch?.filter(
            (externalCompanyDataFetch) => {
              return !externalCompanies.some(
                (externalCompany: { company: string }) => {
                  return (
                    externalCompanyDataFetch.companyName ===
                    externalCompany.company
                  );
                }
              );
            }
          );

          const arrayWorksIDsToDelete: string[] = [];

          removeExternalCompanies.forEach((company) => {
            company.externalWorkIDs.forEach((id: string) => {
              if (externalWorksFetchIDs.includes(id)) {
                arrayWorksIDsToDelete.push(id);
              }
            });
          });

          if (arrayWorksIDsToDelete.length > 0) {
            await client.mutate({
              mutation: DELETE_EXTERNAL_WORKS_BY_IDS,
              variables: {
                ids: arrayWorksIDsToDelete,
              },
            });
          }

          if (newExternalWorks.length > 0) {
            const companiesToCreate = newExternalWorks.filter(
              (newExternalWork: { id: string }) =>
                newExternalWork.id === 'new_company'
            );
            const existingCompanies = newExternalWorks.filter(
              (newExternalWork: { id: string }) =>
                newExternalWork.id !== 'new_company'
            );

            companiesToCreate.length > 0 &&
              (await Promise.all(
                companiesToCreate.map(
                  async (company: {
                    company: string;
                    work: string;
                    operatorsNumber: string;
                  }) => {
                    const {
                      data: { createOneExternalCompany },
                    } = await client.mutate({
                      mutation: CREATE_EXTERNAL_COMPANY,
                      variables: {
                        companyName: company.company,
                      },
                    });

                    return client.mutate({
                      mutation: CREATE_EXTERNAL_WORK,
                      variables: {
                        input: {
                          operatorsNumber: Number(company.operatorsNumber),
                          externalCompanyId: createOneExternalCompany.id,
                          actionId: orderActionData.actionId,
                        },
                      },
                    });
                  }
                )
              ));

            existingCompanies.length > 0 &&
              isExternal &&
              (await Promise.all(
                existingCompanies.map(
                  async (company: {
                    work: string;
                    operatorsNumber: string;
                    id: string;
                  }) => {
                    return client.mutate({
                      mutation: CREATE_EXTERNAL_WORK,
                      variables: {
                        input: {
                          operatorsNumber: Number(company.operatorsNumber),
                          externalCompanyId: company.id,
                          actionId: orderActionData.actionId,
                        },
                      },
                    });
                  }
                )
              ));
          }

          await client.query({
            query: FETCH_EXTERNAL_COMPANIES,
            fetchPolicy: 'network-only',
          });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const materialAnimalsAndMachineryCalls: Promise<any>[] = [];

      // Create and remove material, aux machine and animals units
      if (materialsData.isMaterialTableDisplayed === 'false') {
        if (action.materialUnitIDs.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_MATERIALS,
            variables: {
              ids: action.materialUnitIDs,
            },
          });
        }
      }
      if (materialsData.isMaterialTableDisplayed === 'true') {
        const materialUnits = await Promise.all(
          action?.materialUnitIDs?.map(async (materialUnitId: string) => {
            const {
              data: { materialUnit },
            } = await client.query({
              query: GET_MATERIAL_UNIT_BY_ID,
              variables: {
                id: materialUnitId,
              },
            });

            return materialUnit;
          })
        );

        const arrayUpdatedMaterials: {
          materialUnitId: string;
          newValue: number;
        }[] = [];

        materialUnits.forEach((materialUnit) => {
          Object.values<MaterialIdTypes>(materialsData?.materialsTable).filter(
            (material: MaterialIdTypes) => {
              if (
                material.id === materialUnit.materialIDs[0] &&
                Number(material.ut) !== materialUnit.unit
              ) {
                arrayUpdatedMaterials.push({
                  materialUnitId: materialUnit.id,
                  newValue: Number(material?.ut),
                });
              }
            }
          );
        });

        if (arrayUpdatedMaterials.length > 0) {
          await client.mutate({
            mutation: UPDATE_MANY_MATERIALS_UNITS,
            variables: {
              input: arrayUpdatedMaterials,
            },
          });
        }

        const removeMaterials = materialUnits
          .filter((materialUnit) => {
            return !Object.values<MaterialIdTypes>(
              materialsData.materialsTable
            ).some((materialId: MaterialIdTypes) => {
              return materialId.id.includes(materialUnit.materialIDs);
            });
          })
          .map((material) => material.id);

        if (removeMaterials.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_MATERIALS,
            variables: {
              ids: removeMaterials,
            },
          });
        }
        const newMaterials: MaterialIdTypes[] = Object.values<MaterialIdTypes>(
          materialsData?.materialsTable
        ).filter((materialID: MaterialIdTypes) => {
          return !materialUnits.some((materialUnit) => {
            return materialUnit.materialIDs.includes(materialID?.id);
          });
        });
        if (newMaterials.length > 0) {
          for (const materialsDataKey of newMaterials) {
            materialAnimalsAndMachineryCalls.push(
              client.mutate({
                mutation: CREATE_NEW_MATERIAL_UNIT,
                variables: {
                  input: {
                    unit: Number(materialsDataKey.ut),
                    actionId: orderActionData.actionId,
                    materialId: materialsDataKey.id,
                  },
                },
                update(cache, { data: { createMaterialUnit } }) {
                  cache.modify({
                    fields: {
                      materialUnits(existingMaterialUnits = []) {
                        const newMaterialUnitRef = cache.writeFragment({
                          data: createMaterialUnit,
                          fragment: NEW_MATERIAL_UNIT,
                        });
                        return [...existingMaterialUnits, newMaterialUnitRef];
                      },
                    },
                  });
                },
              })
            );
          }
        }
      }

      if (animalsData.isAnimalsTableDisplayed === 'false') {
        if (action.animalUnitIDs.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_ANIMAL_UNITS,
            variables: {
              ids: action.animalUnitIDs,
            },
          });
        }
      }

      if (animalsData.isAnimalsTableDisplayed === 'true') {
        const animalsUnits = await Promise.all(
          action?.animalUnitIDs?.map(async (animalUnitId: string) => {
            const {
              data: { animalUnit },
            } = await client.query({
              query: GET_ANIMAL_UNIT_BY_ID,
              variables: {
                id: animalUnitId,
              },
            });

            return animalUnit;
          })
        );

        const arrayUpdatedAnimals: {
          animalUnitId: string;
          newValue: number | undefined;
          animalStatusId: string | undefined;
        }[] = [];
        animalsUnits.forEach((animalUnit) => {
          Object.values<AnimalIdTypes>(animalsData?.animalsTable).filter(
            (animal: AnimalIdTypes) => {
              if (
                (animal.id === animalUnit.animalIDs[0] &&
                  Number(animal.ut) !== animalUnit.unit) ||
                (animal.id === animalUnit.animalIDs[0] &&
                  animalUnit.animalStatusIDs[0] !== animal.statusId)
              ) {
                arrayUpdatedAnimals.push({
                  animalUnitId: animalUnit.id,
                  newValue:
                    Number(animal.ut) !== animalUnit.unit
                      ? Number(animal.ut)
                      : undefined,
                  animalStatusId:
                    animalUnit.animalStatusIDs[0] !== animal.statusId
                      ? animal.statusId
                      : undefined,
                });
              }
            }
          );
        });

        if (arrayUpdatedAnimals.length > 0) {
          await client.mutate({
            mutation: UPDATE_MANY_ANIMAL_UNITS,
            variables: {
              input: arrayUpdatedAnimals,
            },
          });
        }

        const removeAnimals = animalsUnits
          .filter((animalUnit) => {
            return !Object.values<AnimalIdTypes>(
              animalsData?.animalsTable
            ).some((animalId: AnimalIdTypes) => {
              return animalId?.id.includes(animalUnit?.animalIDs);
            });
          })
          .map((animal) => animal.id);

        if (removeAnimals.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_ANIMAL_UNITS,
            variables: {
              ids: removeAnimals,
            },
          });
        }

        const newAnimals: AnimalIdTypes[] = Object.values<AnimalIdTypes>(
          animalsData?.animalsTable
        ).filter((animalID: AnimalIdTypes) => {
          return !animalsUnits.some((animalUnit) => {
            return animalUnit?.animalIDs.includes(animalID?.id);
          });
        });
        if (newAnimals.length > 0) {
          for (const animalsDataKey of newAnimals) {
            materialAnimalsAndMachineryCalls.push(
              client.mutate({
                mutation: CREATE_NEW_ANIMAL_UNIT,
                variables: {
                  input: {
                    unit: Number(animalsDataKey.ut),
                    actionId: orderActionData.actionId,
                    animalId: animalsDataKey.id,
                    animalStatusId: animalsDataKey.statusId,
                  },
                },
                update(cache, { data: { createAnimalUnit } }) {
                  cache.modify({
                    fields: {
                      animalUnits(existingAnimalUnits = []) {
                        const newAnimalUnitRef = cache.writeFragment({
                          data: createAnimalUnit,
                          fragment: NEW_ANIMAL_UNIT,
                        });
                        return [...existingAnimalUnits, newAnimalUnitRef];
                      },
                    },
                  });
                },
              })
            );
          }
        }
      }

      if (machineryData.isMachineryTableDisplayed === 'false') {
        if (action.auxMachineUnitIDs.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_AUX_MACHINE_UNITS,
            variables: {
              ids: action.auxMachineUnitIDs,
            },
          });
        }
      }

      if (machineryData.isMachineryTableDisplayed === 'true') {
        const auxMachineUnits = await Promise.all(
          action?.auxMachineUnitIDs?.map(async (auxMachineUnitId: string) => {
            const {
              data: { auxMachineUnit },
            } = await client.query({
              query: GET_AUX_MACHINE_UNIT_BY_ID,
              variables: {
                id: auxMachineUnitId,
              },
            });

            return auxMachineUnit;
          })
        );

        const arrayUpdatedAuxMachine: {
          auxMachineUnitId: string;
          newValue: number;
        }[] = [];
        auxMachineUnits.forEach((auxMachineUnit) => {
          Object.values<AuxMachineTypes>(machineryData?.machineryTable).filter(
            (auxMachine: AuxMachineTypes) => {
              if (
                auxMachine.id === auxMachineUnit.auxMachineIDs[0] &&
                Number(auxMachine.ut) !== auxMachineUnit.unit
              ) {
                arrayUpdatedAuxMachine.push({
                  auxMachineUnitId: auxMachineUnit.id,
                  newValue: Number(auxMachine.ut),
                });
              }
            }
          );
        });

        if (arrayUpdatedAuxMachine.length > 0) {
          await client.mutate({
            mutation: UPDATE_MANY_AUX_MACHINE_UNITS,
            variables: {
              input: arrayUpdatedAuxMachine,
            },
          });
        }

        const removeAuxMachineUnits = auxMachineUnits
          .filter((auxMachineUnit) => {
            return !Object.values<AuxMachineTypes>(
              machineryData?.machineryTable
            ).some((auxMachineId: AuxMachineTypes) => {
              return auxMachineId?.id.includes(auxMachineUnit?.auxMachineIDs);
            });
          })
          .map((auxMachine) => auxMachine.id);

        if (removeAuxMachineUnits.length > 0) {
          await client.mutate({
            mutation: DELETE_MANY_AUX_MACHINE_UNITS,
            variables: {
              ids: removeAuxMachineUnits,
            },
          });
        }

        const newAuxMachine: AuxMachineTypes[] = Object.values<AuxMachineTypes>(
          machineryData?.machineryTable
        ).filter((auxMachineId: AuxMachineTypes) => {
          return !auxMachineUnits.some((auxMachineUnit) => {
            return auxMachineUnit?.auxMachineIDs.includes(auxMachineId?.id);
          });
        });

        if (newAuxMachine.length > 0) {
          for (const machineryDataKey of newAuxMachine) {
            materialAnimalsAndMachineryCalls.push(
              client.mutate({
                mutation: CREATE_NEW_AUX_MACHINE_UNIT,
                variables: {
                  input: {
                    unit: Number(machineryDataKey.ut),
                    actionId: orderActionData.actionId,
                    auxMachineId: machineryDataKey.id,
                  },
                },
                update(cache, { data: { createAuxMachineUnit } }) {
                  cache.modify({
                    fields: {
                      auxMachineUnits(existingAuxMachineUnits = []) {
                        const newAuxMachineRef = cache.writeFragment({
                          data: createAuxMachineUnit,
                          fragment: NEW_AUX_MACHINE_UNIT,
                        });
                        return [...existingAuxMachineUnits, newAuxMachineRef];
                      },
                    },
                  });
                },
              })
            );
          }
        }
      }
      let geoPositionPlace;
      if (locationData?.actionCoordinates) {
        const actionCoordinatesParse = JSON.parse(
          locationData?.actionCoordinates
        );
        geoPositionPlace = {
          latitude: actionCoordinatesParse.lat,
          longitude: actionCoordinatesParse.lng,
        };
      }
      // Updates almost all except images and actions relationships
      const [
        {
          data: { createGeoPositionAction },
        },
      ] = await Promise.all([
        client.mutate({
          mutation: CREATE_POSITION_ACTION,
          variables: {
            input: {
              pkInitKm: Number(pkInitRaw[0]),
              pkInitMeter: Number(pkInitRaw[1]),
              pkEndKm: Number(pkEndRaw[0]),
              pkEndMeter: Number(pkEndRaw[1]),
              track: locationData.actionTrack,
              direction: locationData?.actionDirection ?? UNSPECIFIC,
              margin: locationData?.actionMargin ?? UNSPECIFIC,
              lane: locationData.actionLane,
              latitude: geoPositionPlace?.latitude,
              longitude: geoPositionPlace?.longitude,
              roadId: locationData.actionRoad,
              concessionId: locationData.actionConcession,
              actionId: orderActionData.actionId,
            },
          },
          update(cache, { data: { createGeoPositionAction } }) {
            cache.modify({
              fields: {
                geoPositionActions(existingGeoPositionActions = []) {
                  const newGeoPositionActionRef = cache.writeFragment({
                    data: createGeoPositionAction,
                    fragment: NEW_POSITION_ACTION,
                  });
                  return [
                    ...existingGeoPositionActions,
                    newGeoPositionActionRef,
                  ];
                },
              },
            });
          },
        }),
        ...materialAnimalsAndMachineryCalls,
        client.mutate({
          mutation: UPDATE_ORDER_RELATIONSHIPS,
          variables: {
            input: {
              id: orderActionData.orderId,
              capitol: editionData.editionCapitol,
              subCapitol: editionData.editionSubCapitol,
              elementOrder: editionData.editionElementOrder,
            },
          },
        }),
        client.mutate({
          mutation: UPDATE_ACTION,
          variables: {
            input: {
              id: orderActionData.actionId,
              typeOfHour: operatorsAndVehiclesData.hoursType,
              descriptionValidated: editionData.editionDescription,
              laneCutting:
                editionData.editionLaneCutting &&
                editionData.editionLaneCutting === 'true',
              assetSwitch: editionData.editionAssetSwitch === 'true',
              minutesTotals,
              registerInitDate: operatorsAndVehiclesData.registerInitDate,
              track: locationData.actionTrack,
              direction: locationData.actionDirection ?? null,
              margin: locationData.actionMargin ?? null,
              lane: locationData.actionLane,
              pkInitKm: pkInitRaw ? Number(pkInitRaw[0]) : undefined,
              pkInitMeter: pkInitRaw ? Number(pkInitRaw[1]) : undefined,
              pkEndKm: pkEndRaw ? Number(pkEndRaw[0]) : undefined,
              pkEndMeter: pkEndRaw ? Number(pkEndRaw[1]) : undefined,
              isExternal,
            },
          },
        }),
        client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            input: {
              id: orderActionData.orderId,
              state: OrderState.Open,
              registerInitDate: operatorsAndVehiclesData.registerInitDate,
              registerEndDate: operatorsAndVehiclesData.registerEndDate,
            },
          },
        }),
      ]);
      !isExternal &&
        (await Promise.all([
          ...driverAndVehicle.map(
            async (driverAndVehicle: {
              driverID: string;
              vehicleID: string;
            }) => {
              const vehicleOccupant = await client.mutate({
                mutation: NEW_VEHICLE_OCCUPANT_ACTION,
                variables: {
                  input: {
                    userDriver: driverAndVehicle.driverID,
                    vehicleId: driverAndVehicle.vehicleID,
                    actionId: orderActionData.actionId,
                  },
                },
                update(cache, { data: { createVehicleOccupantAction } }) {
                  cache.modify({
                    fields: {
                      vehicleOccupants(existingVehicleOccupants = []) {
                        const newVehicleOccupantRef = cache.writeFragment({
                          data: createVehicleOccupantAction,
                          fragment: NEW_VEHICLE_OCCUPANT_ACTION,
                        });
                        return [
                          ...existingVehicleOccupants,
                          newVehicleOccupantRef,
                        ];
                      },
                    },
                  });
                },
              });

              driverAndVehicleResolved.push(
                vehicleOccupant.data.createVehicleOccupantAction.id
              );
              client.query({
                fetchPolicy: 'network-only',
                query: GET_VEHICLE_BY_ID,
                variables: {
                  id: driverAndVehicle.vehicleID,
                },
              });
              return vehicleOccupant;
            }
          ),
        ]));

      const orderImages = await Promise.all(
        data.order.imageIDs.map(async (imageItem: string) => {
          const {
            data: { image },
          } = await client.query({
            query: GET_IMAGE_BY_ID,
            variables: {
              id: imageItem,
            },
          });

          return image;
        })
      );

      const selectedImages = await UploadImage(OrderType.Action);

      const newImages = selectedImages.filter((selectedImage) => {
        return !orderImages.some((orderImage) => {
          return selectedImage.name === orderImage.name;
        });
      });

      const removeImages = orderImages
        .filter((orderImage) => {
          return !selectedImages.some((selectedImage) => {
            return orderImage.name === selectedImage.name;
          });
        })
        .map((image) => image.id);

      if (removeImages.length > 0) {
        await client.mutate({
          mutation: DELETE_MANY_IMAGES,
          variables: {
            ids: removeImages,
          },
        });
      }

      await Promise.all(
        newImages.map(
          async (image) =>
            await client.mutate({
              mutation: CREATE_NEW_IMAGE,
              variables: {
                input: {
                  name: image.name,
                  creatingDate: image.creatingDate,
                  cloudinaryPublicId: image.cloudinaryPublicId,
                  orderId: orderActionData.orderId,
                },
              },
              update(cache, { data: { createImage } }) {
                cache.modify({
                  fields: {
                    images(existingImages = []) {
                      const newImageRef = cache.writeFragment({
                        data: createImage,
                        fragment: NEW_IMAGE,
                      });
                      return [...existingImages, newImageRef];
                    },
                    orders(existingOrders = {}) {
                      const newOrderRef = {
                        __ref: `Order:${orderActionData.orderId}`,
                      };

                      const truncatedArray = truncateArrayAfterNull(
                        existingOrders.orders
                      );

                      const newOrdersArray = [newOrderRef, ...truncatedArray];

                      newOrdersArray.splice(-2, 1);

                      const setCursorMultimedia = {
                        ...cursorMultimedia,
                        take: takeBase,
                        cursor: '',
                        orderBy: 'desc',
                      };

                      if (existingOrders?.orders?.length > 0) {
                        dispatch(
                          setCursor(tableIds.MULTIMEDIA, setCursorMultimedia)
                        );
                      }

                      return {
                        ...existingOrders,
                        orders: newOrdersArray,
                        totalCount: existingOrders.totalCount + 1,
                        pageInfo: {},
                      };
                    },
                  },
                });
              },
            })
        )
      );
      // Update Action Relationships
      await client.mutate({
        mutation: UPDATE_ACTION_RELATIONSHIPS,
        variables: {
          input: {
            id: orderActionData.actionId,
            userRelationships: operatorIDs,
            vehicleOccupantRelationships: driverAndVehicleResolved,
            geoPositionActionId: createGeoPositionAction.id,
            activityId: editionData.editionActivity,
            assetId: editionData?.editionAsset,
            concessionId: locationData.actionConcession,
            roadId: locationData.actionRoad,
            capitolId: editionData.editionCapitol,
            subCapitolId: editionData.editionSubCapitol,
          },
        },
      });

      await Promise.all([
        ...operatorIDs.map((operatorId: string) =>
          client.query({
            fetchPolicy: 'network-only',
            query: GET_USER_BY_ID,
            variables: {
              input: {
                id: operatorId,
              },
            },
          })
        ),
        client.query({
          fetchPolicy: 'network-only',
          query: GET_ACTIVITY_BY_ID,
          variables: { id: editionData.editionActivity },
        }),
        client.query({
          fetchPolicy: 'network-only',
          query: GET_ACTION_BY_ID,
          variables: { id: orderActionData.actionId },
        }),
        client.query({
          fetchPolicy: 'network-only',
          query: GET_ORDER_BY_ID,
          variables: { id: orderActionData.orderId },
        }),
      ]);
      if (editionData?.editionAsset) {
        await client.query({
          fetchPolicy: 'network-only',
          query: GET_ASSET_BY_ID,
          variables: { id: editionData.editionAsset },
        });
      }

      const {
        data: { order },
      } = await client.query({
        query: GET_ORDER_BY_ID,
        variables: {
          id: orderActionData.orderParentId,
        },
      });

      Promise.all([
        client.query({
          fetchPolicy: 'network-only',
          query: GET_ORDER_BY_ID,
          variables: {
            id: orderActionData.orderParentId,
          },
        }),
        client.query({
          fetchPolicy: 'network-only',
          query:
            order.type === OrderType.Generic
              ? GET_GENERIC_ORDER_BY_ID
              : GET_CAMPAIGN_BY_ID,
          variables: {
            id:
              order.type === OrderType.Generic
                ? order.genericOrdersIDs[0]
                : order.campaignIDs[0],
          },
        }),
      ]);
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('> Error updating action', err);
  }

  return redirect(`../../summary/${orderActionData.actionId}`);
};
