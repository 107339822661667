import { redirect } from 'react-router-dom';

// GraphQL
import { client } from '../../../../gql/client';
import { CREATE_NEW_ORDER } from '../../../../gql/mutations/orders';
import { CREATE_NEW_ACTION } from '../../../../gql/mutations/actions';
import { GET_ORDER_BY_ID } from '../../../../gql/queries/orders/orders';

// Types
import { OrderState, OrderType } from '../../../../types/orders';

//Utils
import {
  compressSessionStorage,
  decompressSessionStorage,
} from '../../../../utils/sessionStorage';
import { NEW_ACTION } from '../../../../gql/fragments/action';
import { getCursor } from '../../../../state/selectors/ui/tables';
import { setCursor } from '../../../../state/actions/ui/cursorTables';
import {
  tableIds,
  takeBase,
} from '../../../../components/display/tables/types';
import { store } from '../../../../state/configureStore';
import { truncateArrayAfterNull } from '../../../../utils/mergeArrays';
import { NEW_ORDER } from '../../../../gql/fragments/orders';

export const saveData = async (
  dataFormRaw: string | null,
  orderParentId: FormDataEntryValue | null,
  orderParentCotic: FormDataEntryValue | null,
  sessionStorageKey: string
) => {
  const dispatch = store.dispatch;

  if (dataFormRaw) {
    const dataForm = JSON.parse(decompressSessionStorage(dataFormRaw));

    const cursorActions = getCursor(tableIds.VALIDATION_ACTIONS)(
      store.getState()
    );

    const creationDate = new Date().toISOString();
    //DOC: create order
    const orderInput = {
      type: OrderType.Action,
      creationDate,
      state: OrderState.Partial,
      description: dataForm.description,
      classification: dataForm.classification,
      capitol: dataForm.capitol,
      subCapitol: dataForm.subCapitol,
      elementOrder: dataForm?.actionElementOrder,
      currentExpeditionIndex: Number(
        (orderParentCotic as string).split('.')[2]
      ) as number,
      parentExpeditionOrderID: orderParentId as string,
    };
    const dataOrder = await client.mutate({
      mutation: CREATE_NEW_ORDER,
      variables: {
        input: orderInput,
      },
      update(cache, { data: { createOrder } }) {
        cache.modify({
          id: `Order:${orderParentId}`,
          fields: {
            childExpeditionOrderIDs(variables = [], { readField }) {
              const newOrderRef = cache.writeFragment({
                data: createOrder,
                fragment: NEW_ORDER,
              });
              const newId = readField('id', newOrderRef);
              return [...variables, newId];
            },
          },
        });
      },
    });

    //DOC: create action order

    const pkInitArray = dataForm.pkInit.replace(/ /g, '').split('+');
    const pkEndArray = dataForm.pkEnd.replace(/ /g, '').split('+');

    const dataAction = await client.mutate({
      mutation: CREATE_NEW_ACTION,
      variables: {
        input: {
          indexExpedition: dataOrder.data.createOrder?.indexExpedition,
          indexType: dataOrder.data.createOrder?.indexType,
          concessionId: dataForm.concession,
          roadId: dataForm.road,
          track: dataForm.track,
          lane: dataForm.lane,
          direction: dataForm?.direction,
          margin: dataForm?.margin,
          activityId: dataForm?.actionActivity,
          pkInitKm: Number(pkInitArray[0]),
          pkInitMeter: Number(pkInitArray[1]),
          pkEndKm: Number(pkEndArray[0]),
          pkEndMeter: Number(pkEndArray[1]),
          orderId: dataOrder.data.createOrder.id,
          capitolId: dataForm.capitol,
          subCapitolId: dataForm.subCapitol,
          creationDate,
        },
      },
      update(cache, { data: { createAction } }) {
        cache.modify({
          fields: {
            actions(variables = {}) {
              const newActionRef = cache.writeFragment({
                data: createAction,
                fragment: NEW_ACTION,
              });

              const truncatedArray = truncateArrayAfterNull(variables.actions);

              const newActionsArray = [newActionRef, ...truncatedArray];

              newActionsArray.splice(-2, 1);

              if (variables?.actions?.length <= 14) {
                const dataCursorActions = {
                  ...cursorActions,
                  take: takeBase,
                  cursor: '',
                  orderBy: 'desc',
                };

                dispatch(
                  setCursor(tableIds.VALIDATION_ACTIONS, dataCursorActions)
                );

                return {
                  ...variables,
                  actions: newActionsArray,
                  totalCount: variables.totalCount + 1,
                  pageInfo: {},
                };
              }

              if (variables?.actions.length > 0) {
                const dataCursorActions = {
                  ...cursorActions,
                  cursor: '',
                  take: takeBase,
                  orderBy: 'desc',
                };
                dispatch(
                  setCursor(tableIds.VALIDATION_ACTIONS, dataCursorActions)
                );
              }
              return {
                ...variables,
                actions: newActionsArray,
                totalCount: variables.totalCount + 1,
                pageInfo: {},
              };
            },
          },
        });
      },
    });

    // Refetch in parallel
    await client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_BY_ID,
      variables: { id: dataOrder.data.createOrder.id },
    });
    sessionStorage.setItem(
      sessionStorageKey,
      compressSessionStorage(
        JSON.stringify({
          ...dataForm,
          orderId: dataOrder.data.createOrder.id,
          actionId: dataAction.data.createAction.id,
        })
      )
    );
  }

  return redirect('details/operators-and-vehicles');
};
